// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ENV_PREFIX: 'dev',
  apiDomain: 'https://dev-api.hiosocial.com/',
  apiUrl: 'https://dev-api.hiosocial.com/api',
  microservicesApiUrl: 'https://dev-apigateway.hiosocial.com',
  cloudApiUrl: 'https://us-central1-contap-dev.cloudfunctions.net',
  eventbriteURL:
    'https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=HFL7R2O7WGBHUTOB6G',
  agreementURL: 'https://na3.docusign.net/Member/PowerFormSigning.aspx?PowerFormId=ae214533-c459-4dd9-9796-7648a579df1c&env=na3&acct=8616a82d-da67-4dcd-a1eb-de4887b1d762&v=2',
  stripeKey: 'pk_test_NLFDsFyuI16JD2tNmyiTQH4f00BjqcB1JT',
  googleTimezoneKey: 'AIzaSyCDYGZ5Wdwr4FpHjepubTbvwYvgXM9Qh0M',
  highlightPackage: 25,
  mapBoxKey: 'pk.eyJ1IjoianRjcmFwYXJvIiwiYSI6ImNqcDhwaHVhazAyNzMza3BkYXB1czVlenUifQ.-SDUBSJeqR77lC69UDXucw',
  sendBirdAppId: "A4050AAB-499F-4EAB-9A0A-2CB085DEA803",
  pluginUrl: "https://dev-plugin-helper.hiosocial.com/plugin_loader.js",
  firebaseConfig: {
    apiKey: "AIzaSyCb2MGBdvhrHBVpAQVcoAQMy1sBdGMcOAc",
    authDomain: "contap-dev.firebaseapp.com",
    databaseURL: "https://contap-dev.firebaseio.com",
    projectId: "contap-dev",
    storageBucket: "contap-dev.appspot.com",
    messagingSenderId: "531331783690",
    appId: "1:531331783690:web:7f73547adfcaf8364353f3",
    measurementId: "G-WTCXT7RS30"
  },
  firebaseDefaultPassword: '123456',
  PLUGIN_INTEGRATION_TOKEN_COLLECION: 'plugin_integration_token',
  ANALYTICS_OVERVIEW_COLLECION: 'AnalyticsDailyOverview',
  ANALYTICS_DEMOGRAPHIC_COLLECION: 'AnalyticsDemographic',
  ANALYTICS_CHANNEL_COLLECION: 'AnalyticsChannel',
  VIP_EVENT_COLLECTION: 'dev_Event',
  VIP_TABLE_COLLECTION: 'VIP_Table',
  VIP_LOUNGE_MEMBERS_COLLECTION: 'members',
  LOUNGE_COLLECTION: 'Lounge_Members',
  AgoraAppId: 'b8d69041eaf34c15980cac10c8e367f9',
  CONSUMER_KEY: '-LHIHYbca17ogALo4IofXA==',
  CONSUMER_SECRET: 'fNynlbea_J95YN52tQnk2IvCxJlc4RJA_YdDQXIW85w=',
  CONFERENCE_CHANNEL_NAME: 'dev-event-',
  VIRTUAL_MODERATED_COLLECTION: 'dev_virtual_moderated',
  VIRTUAL_MODERATED_ACTIVE_MEMBERS_COLLECTION: 'active_members',
  VIRTUAL_MODERATED_BREAKOUT_ROOMS_COLLECTION: 'breakout_rooms',
  BREAKOUT_ROOM_MEMBERS_COLLECTION: 'room_members',
  VIRTUAL_SPEED_COLLECTION: 'dev_virtual_speed',
  VIRTUAL_SPEED_MEMBERS_COLLECTION: 'speedMembers',
  VIRTUAL_SPEED_TABLE_COLLECTION: 'VIP_Table',
  VIRTUAL_SPEED_TABLE_MEMBERS_COLLECTION: 'member',
  host: "https://dev.hiosocial.com/",
  FIREBASE_SW: 'firebase-messaging-sw-dev.js',
  captchaSiteKey: '6LfezfAaAAAAAKosr67J0AXmJLt1TiDVkUToQEl2',
  product: 'events'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
